var render = function render(){var _vm=this,_c=_vm._self._c;return _c('loader',_vm._b({attrs:{"text":"Loading Locations"}},'loader',{ loading: _vm.loading },false),[_c('columns',[_c('column',[_c('page-heading',{attrs:{"heading":"Locations"}})],1),_c('column',{staticClass:"is-5"},[_c('form',[_c('text-input',{attrs:{"classes":"has-addons is-rounded","placeholder":"Search locations..."},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('div',{staticClass:"buttons has-addons is-rounded"},[_c('submit-button',{staticClass:"is-rounded",on:{"submit":_vm.runSearch}},[_vm._v(" Search ")]),(_vm.$route.query.search)?_c('action-button',{on:{"click":_vm.clearFilters}},[_c('icon',{attrs:{"icon":"times"}})],1):_vm._e(),(!_vm.$root.isPassiveUser())?_c('router-link',{staticClass:"button is-rounded is-flex",attrs:{"to":{
                  name: 'create-location'
                }}},[_c('icon',{attrs:{"icon":"plus"}}),_c('span',[_vm._v("Location")])],1):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)])],1),_c('columns',[_c('column',[_c('action-button',{staticClass:"is-small is-rounded",attrs:{"working":_vm.downloading,"left-icon":"download"},on:{"click":_vm.downloadExcel}},[_vm._v(" Excel ")])],1)],1),_c('columns',[_c('column',[_c('div',{staticClass:"index-rows"},_vm._l((_vm.locations.data),function(location){return _c('div',{key:location.id,staticClass:"box is-marginless"},[_c('columns',{staticClass:"is-mobile"},[_c('column',{staticClass:"is-half-mobile"},[_c('router-link',{staticClass:"has-text-weight-bold is-block",attrs:{"to":{
                name: 'location-manager',
                params: {
                  location: location.uuid
                }
              }}},[_vm._v(" "+_vm._s(location.customer.name)+" - "+_vm._s(location.name)+" ")]),_c('small',{staticClass:"has-text-grey"},[_vm._v(_vm._s(_vm._f("ucwords")(location.type))+", Updated "+_vm._s(_vm._f("asCalendar")(location.updated_at)))])],1),_c('column',{staticClass:"is-one-fifth-tablet is-two-quarters-mobile is-flex is-align-items-center is-justify-content-end"},[_c('index-info',{attrs:{"label":"Customer","info":location.customer.name}})],1),_c('column',{staticClass:"is-one-fifth-tablet is-two-quarters-mobile is-flex is-align-items-center is-justify-content-end"},[_c('index-info',{attrs:{"icon":"building-flag","label":"Branches","info":location.branches_count}})],1)],1)],1)}),0),(_vm.locations.data.length)?_c('pager',{attrs:{"pageable":_vm.locations,"context":"Location","jump-controls":""},on:{"nav":_vm.goToPage}}):_vm._e(),_c('no-items-to-display',{attrs:{"item-count":_vm.locations.data.length,"with-link":"","heading":"There are no locations available for display.","to":{
          name: 'create-location'
        },"to-text":"New Location"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }