<template>
  <loader v-bind="{ loading }" text="Loading Locations">
    <columns>
      <column>
        <page-heading
          heading="Locations"
        />
      </column>
      <column class="is-5">
        <form>
          <text-input 
            v-model="filters.search"
            classes="has-addons is-rounded"
            placeholder="Search locations...">
            <template #right>
              <div class="buttons has-addons is-rounded">
                <submit-button 
                  @submit="runSearch"
                  class="is-rounded">
                  Search
                </submit-button>
                <action-button
                  v-if="$route.query.search"
                  @click="clearFilters">
                  <icon icon="times"/> 
                </action-button>
                <router-link 
                  v-if="!$root.isPassiveUser()"
                  class="button is-rounded is-flex" 
                  :to="{
                    name: 'create-location'
                  }">
                    <icon icon="plus"/>
                    <span>Location</span>
                  </router-link>
              </div>
            </template>
          </text-input>
        </form>
      </column>
    </columns>

    <columns>
      <column>
        <action-button :working="downloading" @click="downloadExcel" left-icon="download" class="is-small is-rounded">
          Excel
        </action-button>
      </column>
    </columns>

    <columns>
      <column>
        <div class="index-rows">
          <div class="box is-marginless" v-for="location in locations.data" :key="location.id">
            <columns class="is-mobile">
              <column class="is-half-mobile">
                <router-link class="has-text-weight-bold is-block" :to="{
                  name: 'location-manager',
                  params: {
                    location: location.uuid
                  }
                }">
                    {{ location.customer.name }} - {{ location.name }}
                </router-link>
                <small class="has-text-grey">{{ location.type | ucwords }}, Updated {{ location.updated_at | asCalendar }}</small>
              </column>
              <column class="is-one-fifth-tablet is-two-quarters-mobile is-flex is-align-items-center is-justify-content-end">
                <index-info 
                  label="Customer" 
                  :info="location.customer.name"
                />
              </column>
              <column class="is-one-fifth-tablet is-two-quarters-mobile is-flex is-align-items-center is-justify-content-end">
                <index-info 
                  icon="building-flag" 
                  label="Branches" 
                  :info="location.branches_count"
                />
              </column>
            </columns>
          </div>
        </div>
        <pager v-if="locations.data.length" :pageable="locations" context="Location" jump-controls @nav="goToPage" />

        <no-items-to-display
          :item-count="locations.data.length"
          with-link
          heading="There are no locations available for display."
          :to="{
            name: 'create-location'
          }"
          to-text="New Location"
        />

      </column>
    </columns>
  </loader>
</template>
<script>
import { get } from '@/api/request'
import { common as backend } from '@/api'
import download from 'downloadjs'

export default {

  data: () => ({
    loading: true,
    downloading: false,
    filters: {
      search: ''
    },
    locations: {
      data: []
    }
  }),

  created() {
    if(this.$route.query) {
      this.filters.search = this.$route.query.search
    }
    this.loadLocations()
  },

  methods: {
    loadLocations() {
      get('v1' + this.$route.fullPath, ({ data }) => {
        this.locations = data
        this.loading = false
      }, () => {
        this.loading = false
      }, { params: this.filters })
    },
    goToPage(path) {
      this.$router.push(path)
    },
    runSearch() {
      this.$router.push({
        name: 'locations',
        query: this.filters
      });
    },
    clearFilters() {
      this.filters.search = ''
      this.$router.push({
        name: 'locations',
      });
    },

    downloadExcel() {
      this.downloading = true
      backend.downloadFile(`/v1/locations/download-xls`, ({ data }) => {
        download(data, `locations.xlsx`, 'xlsx')
        this.downloading = false
      }, () => {
        this.downloading = false
      }, this.filters)
    }
  },

  watch: {
    '$route': 'loadLocations'
  }

}
</script>